var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"login"},[_c('div',{staticClass:"container-new"},[_c('div',{staticClass:"login__image"}),_c('div',{staticClass:"login__form"},[_c('h2',{domProps:{"textContent":_vm._s(_vm.$t('Log into our magical world of books'))}}),_c('validation-observer',{ref:"form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{staticClass:"form-group form-group--horizontal",attrs:{"tag":"div","rules":"required|email","name":_vm.$t('Email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"email"},domProps:{"textContent":_vm._s(_vm.$t('Email'))}}),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control form-input--underline",attrs:{"id":"email","type":"text","placeholder":_vm.$t('john.pitterson@gmail.com')},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(errors.length)?_c('span',{domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}),_c('validation-provider',{staticClass:"form-group form-group--horizontal",attrs:{"tag":"div","rules":"required","name":_vm.$t('Password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-label",attrs:{"for":"password"},domProps:{"textContent":_vm._s(_vm.$t('Password'))}}),_c('div',{staticClass:"form-validation",class:{ 'is-invalid': errors.length }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control form-input--underline",attrs:{"id":"password","autocomplete":"new-password","type":"password","placeholder":"********"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),(errors.length)?_c('span',{domProps:{"textContent":_vm._s(errors[0])}}):_vm._e()])]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-between mt-4"},[_c('div',{staticClass:"form-check"},[_c('label',{staticClass:"form-check__label"},[_c('input',{staticClass:"form-check__input",attrs:{"type":"checkbox","name":"remember"}}),_c('span',{staticClass:"form-check__indicator"}),_vm._v(" "+_vm._s(_vm.$t('Remember me'))+" ")])]),_c('router-link',{attrs:{"to":_vm.$localePath('password-request')}},[_vm._v(" "+_vm._s(_vm.$t('Forgot password?'))+" ")])],1),_c('button',{staticClass:"btn btn--primary",class:{ 'btn--disabled': (validated && invalid) || _vm.loading },attrs:{"disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t('Log In'))+" ")]),_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t("Don't have an account?"))+" "),_c('router-link',{attrs:{"to":_vm.$localePath('signup')}},[_vm._v(" "+_vm._s(_vm.$t('Sign Up'))+" ")])],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }