<template>
  <section class="login">
    <div class="container-new">
      <div class="login__image"></div>
      <div class="login__form">
        <h2 v-text="$t('Log into our magical world of books')"></h2>
        <validation-observer
          v-slot="{ handleSubmit, invalid, validated }"
          ref="form"
          tag="div"
        >
          <form @submit.prevent="handleSubmit(submit)">
            <validation-provider
              v-slot="{ errors }"
              class="form-group form-group--horizontal"
              tag="div"
              rules="required|email"
              :name="$t('Email')"
            >
              <label
                for="email"
                class="form-label"
                v-text="$t('Email')"
              ></label>
              <div
                class="form-validation"
                :class="{ 'is-invalid': errors.length }"
              >
                <input
                  id="email"
                  v-model="form.email"
                  type="text"
                  :placeholder="$t('john.pitterson@gmail.com')"
                  class="form-control form-input--underline"
                />
                <span v-if="errors.length" v-text="errors[0]"></span>
              </div>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              class="form-group form-group--horizontal"
              tag="div"
              rules="required"
              :name="$t('Password')"
            >
              <label
                for="password"
                class="form-label"
                v-text="$t('Password')"
              ></label>
              <div
                class="form-validation"
                :class="{ 'is-invalid': errors.length }"
              >
                <input
                  id="password"
                  v-model="form.password"
                  autocomplete="new-password"
                  type="password"
                  placeholder="********"
                  class="form-control form-input--underline"
                />
                <span v-if="errors.length" v-text="errors[0]"></span>
              </div>
            </validation-provider>
            <div class="d-flex justify-content-between mt-4">
              <div class="form-check">
                <label class="form-check__label">
                  <input
                    type="checkbox"
                    class="form-check__input"
                    name="remember"
                  />
                  <span class="form-check__indicator"></span>
                  {{ $t('Remember me') }}
                </label>
              </div>
              <router-link :to="$localePath('password-request')">
                {{ $t('Forgot password?') }}
              </router-link>
            </div>
            <button
              class="btn btn--primary"
              :class="{ 'btn--disabled': (validated && invalid) || loading }"
              :disabled="loading"
            >
              {{ $t('Log In') }}
            </button>
            <p class="mt-2">
              {{ $t("Don't have an account?") }}
              <router-link :to="$localePath('signup')">
                {{ $t('Sign Up') }}
              </router-link>
            </p>
          </form>
        </validation-observer>
      </div>
    </div>
  </section>
</template>

<i18n src="./translations.json"></i18n>

<script>
import authService from '@/services/authService';
import { mapGetters } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import errors from '@/helpers/errors';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      prevRoute: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      authenticationError: 'auth/authenticationError',
    }),
  },

  methods: {
    async submit() {
      this.loading = true;

      try {
        const loggedIn = await authService.login(this.form);

        if (loggedIn) {
          if (this.prevRoute?.fullPath?.includes('books')) {
            this.$router.push(this.prevRoute.path);
          } else {
            this.$router.push(
              this.$router.history.current.query.redirect || '/'
            );
          }

          return;
        }

        this.$refs.form.setErrors({
          Password: this.$t(errors.LOGIN),
        });
      } catch (e) {
        // console.log(e);
      }

      this.loading = false;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.prevRoute = from;
    });
  },
};
</script>

<style lang="scss">
@import './login.scss';
</style>

fcgfggdgfjhkghdgfhjklfdfxghvjbrxdcfgvhvszdxfc vgxsrdtcfyvkrdjsresesnsmrdg
